<template>
  <div>
    <!-- The modal -->
    <b-modal id="auto">
        <template>
            <h3 class="text-primary">Êtes-vous toujours avec nous?</h3>
        </template>        
        <template #modal-footer>
            <b-button
            variant="success"
            class="ml-1 float-right"
            @click="$bvModal.hide('auto')"
            >
                Oui
            </b-button>
        </template>
    </b-modal>
    <!-- The modal end-->
  </div>  
</template>

<script>
export default {
name: 'auto-logout',
data() {
    return {
      events: ['click','mousemove','mousedown','scroll','keypress','load'],
      warningTimer: null,
      logoutTimer: null,
      warningZone: false,

    };
  },
mounted(){
    this.events.forEach(function(event) {
        window.addEventListener(event, this.resetTimer);
    }, this);
    this.setTimers();
},
destroyed(){
    this.events.forEach(function(event) {
        window.removeEventListener(event, this.resetTimer);
    }, this);
    this.resetTimer();
},
computed:{
        loggedIn(){
        return this.$store.getters.loginState;
        },
       
    },
methods:{
    setTimers(){
        if(this.loggedIn){
            this.warningTimer = setTimeout(this.warningMessage, 20 * 60 * 1000); // 14 * 60 * 1000
            this.logoutTimer = setTimeout(this.logout, 30 * 60 * 1000); // 15 * 60 * 1000
        } else {
            this.warningTimer = null; // 14 * 60 * 1000
            this.logoutTimer = null; // 15 * 60 * 1000
        }
        this.warningZone = false;
    },
    warningMessage(){
        this.warningZone = true;
        this.$bvModal.show('auto')
    },
    logout(){             
        this.$bvModal.hide('auto')
        this.$store.dispatch("logout")
    },
    resetTimer(){
        clearTimeout(this.warningTimer);
        clearTimeout(this.logoutTimer);

        this.setTimers();
    },
},

}
</script>

<style>

</style>