<template>
<div id="app">
    <router-view />
    <auto-logout></auto-logout>
    
</div>
</template>

<script>

export default {
  name: "App",
  mounted(){
    this.$crisp.load('baa38e49-e884-4c09-8131-a81edb2bdb4b');
    if(this.loggedIn==true){
      this.$crisp.set("user:nickname",localStorage.getItem('user'));
      this.$crisp.set("user:phone",localStorage.getItem('phone'));
    }
  },
  computed:{
    loggedIn(){
    return this.$store.getters.loginState;
    },
    UserIn(){
    return this.$store.getters.loginUser;
    },
},
};
</script>
