import Vue from "vue";
import axios from "axios";
import { appConfig } from '../../app.config.js';

const guest = axios.create({
  
  baseURL: appConfig.apiURL,
  
  headers: {
    
    "Content-Type": "multipart/form-data/application/json, application/pdf",
  },
});

Vue.prototype.$guest = guest;

export { guest };