import Vue from 'vue'
import Vuex from 'vuex'
import router from '../router'

Vue.use(Vuex)
export const store = new Vuex.Store({
    state:{
        isLoggedIn: false,
        user: localStorage.getItem("user"),
    },
    getters:{
        loginState: (state) =>{
            return state.isLoggedIn
        },
        loginUser: (state) =>{
            return state.user
        }
    },
    mutations:{
        login(state){
            state.isLoggedIn = true
           // router.push('/');
        },
        logout(state){
            state.isLoggedIn = false
            localStorage.removeItem("user");
            localStorage.removeItem("userId");
            localStorage.removeItem("c_hash_token");
            localStorage.removeItem("idb");
            localStorage.removeItem("id_bailleur");

            router.push("/connexion");
        },
    },
    actions:{
        login({commit}){
            commit("login");
        },
        logout({commit}){
            commit('logout')
        }
    },
});