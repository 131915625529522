import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

import "../public/css/materialdesignicons.min.css";
//import 'swiper/swiper-bundle.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue } from 'bootstrap-vue'

var VueScrollTo = require('vue-scrollto');
import Scrollspy from 'vue2-scrollspy';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
import VueMasonry from 'vue-masonry-css'
import VueLogout from './components/auto-logout.vue'
import { store } from './store/index';
import { guest } from './plugins/axios.js';
import { appConfig } from '../app.config.js';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import CrispChat from '@dansmaculotte/vue-crisp-chat';

// ________ Global variables_________
/*Vue.prototype.$fedapayCDN = appConfig.fedapayCDN // fedapayCDN
Vue.prototype.$fedapayKEY = appConfig.fedapayKEY // fedapayKEY
Vue.prototype.$apiURL = appConfig.apiURL // apiURL*/


import Moment from 'moment';
Moment.locale( 'fr' );
Object.defineProperty( Vue.prototype, '$moment', { value: Moment });

// import Antd from 'ant-design-vue';
//import { Select } from 'ant-design-vue';
//import 'ant-design-vue/dist/antd.css';

import { LMap, LTileLayer, LMarker, LPopup } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
   iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
   iconUrl: require('leaflet/dist/images/marker-icon.png'),
   shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
import LoadScript from "vue-plugin-load-script";


Vue.component('l-popup', LPopup);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


Vue.component('v-select', vSelect);
Vue.component('auto-logout', VueLogout);
Vue.use(CrispChat, {
  websiteId: 'baa38e49-e884-4c09-8131-a81edb2bdb4b',
            
  disabled: true,
  hideOnLoad: true
});
//Vue.$crisp.do('chat:open')

Vue.use(VueScrollTo, {
  duration: 3000,
  easing: "ease"
})

Vue.use(BootstrapVue)
//Vue.use(Select)
Vue.use(Scrollspy);
Vue.use(VueYoutube);
Vue.use(LoadScript);
Vue.use(vueVimeoPlayer)
Vue.use(VueMasonry)
//Vue.use(osm)
//Vue.use(VueGeolocationApi)
//Vue.loadScript("https://cdn.fedapay.com/checkout.js?v=1.1.7")

new Vue({
  router,
  store,
  guest,
  appConfig,
  render: h => h(App)
}).$mount('#app')
