import Vue from "vue";

const appConfig = {
  
  apiURL: "https://immo.azotche.com/",
  fedapayCDN: "https://cdn.fedapay.com/checkout.js?v=1.1.7",
  fedapayKEY: "pk_live_LDpM0eLgiQW-xy94_E6BLKVa",
  
};

Vue.prototype.$appConfig = appConfig;
export { appConfig };
